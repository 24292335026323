import eventbus from "gia/eventbus";
import loadComponents from "gia/loadComponents";
import removeComponents from "gia/removeComponents";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

import { player } from "./components/player/player";

loadComponents({ player });

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
