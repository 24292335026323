import Component from "gia/Component";
import eventbus from "gia/eventbus";

import Plyr from "plyr";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class player extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      button_play: null,
      icon_play: null,
      icon_pause: null,
      //////
      time_current: null,
      time_max: null,
      //////
      mediaEl: null,
      //////
      progressbar: null,
    };
    //////
    this.options = {};
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  mount() {
    const compEl = this.element;
    const refs = this.ref;

    const button_play = refs.button_play;
    const mediaEl = refs.mediaEl;

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    if (mediaEl != undefined) {
      const plyrInst = new Plyr(mediaEl, {
        controls: ["progress"],
      });

      plyrInst.on("timeupdate", () => {
        this.setState({
          playbackTime: plyrInst.currentTime,
        });
      });

      this.setState({
        player: { plyrInst },
      });

      // setTimeout(() => {
      //   time_max.innerHTML = this.format_playbackTime(plyrInst.duration);
      // }, 100);

      /////////////////////////////////
      /////////////////////////////////

      button_play.addEventListener("click", () => {
        const playback = this.state.playback;
        //////
        if (playback === "playing") {
          this.setState({
            playback: "paused",
          });
        } else {
          this.setState({
            playback: "playing",
          });
        }
      });
    }

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    this.setState({});

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    eventbus.on("event", (args) => {});
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  format_playbackTime(time) {
    const hrs = ~~(time / 3600);
    const mins = ~~((time % 3600) / 60);
    const secs = ~~time % 60;

    let time_formatted = "";
    if (hrs > 0) {
      time_formatted += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    time_formatted += "" + mins + ":" + (secs < 10 ? "0" : "");
    time_formatted += "" + secs;
    return time_formatted;
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  stateChange(changes) {
    const refs = this.ref;

    /////////////////////////////////
    /////////////////////////////////

    if ("playback" in changes) {
      const player = this.state.player;
      const playback = changes.playback;
      //////
      const button_play = refs.button_play;
      const icon_play = refs.icon_play;
      const icon_pause = refs.icon_pause;
      //////
      if (playback === "playing") {
        player.plyrInst.play();
        //////
        icon_pause.classList.remove("player__icon-wrapper_hidden");
        icon_play.classList.add("player__icon-wrapper_hidden");
      } else {
        player.plyrInst.pause();
        //////
        icon_pause.classList.add("player__icon-wrapper_hidden");
        icon_play.classList.remove("player__icon-wrapper_hidden");
      }
    }

    /////////////////////////////////
    /////////////////////////////////

    if ("playbackTime" in changes) {
      const player = this.state.player;
      const progressbar = refs.progressbar;
      //////
      const duration = player.plyrInst.duration;
      const playbackTime = changes.playbackTime;
      const percentPlayed = playbackTime / (duration / 100);
      //////
      // progressbar.style = "width: " + percentPlayed + "%;";
      //////
      const time_current = refs.time_current;
      time_current.innerHTML = this.format_playbackTime(playbackTime);
    }
  }
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { player };
